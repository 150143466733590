import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/performance'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
  };

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.database();
        this.firestore = app.firestore();
        this.storage = app.storage();
        this.functions = app.functions();
        this.perf = app.performance();


    }

    // *** Auth API ***
    doCallUser = () => this.auth.signInAnonymously().catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error('there was an issue with anonymous logging', errorMessage)
        // ...
      });

    // *** Firebase function: storage and firestore
    doGetURL = (ref) => {
        return this.storage.ref(ref).getDownloadURL()
    }

    getTime = () => {
        return app.firestore.FieldValue.serverTimestamp();
    }

}

export default Firebase;