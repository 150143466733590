import React, {Component} from 'react';
import {CheckCircle, MusicPlayer} from 'react-bootstrap-icons';
import { FirebaseContext } from '../Firebase';
 

var {v4:uuid} = require('uuid'); //  to generate uid for jobs

class JobProcess extends Component {
  constructor (props) {
    super(props);
    this.state = {
      jobList : [],
      currentUser : null,
      downloadURL : null,
      filetypeError : null,
      error : null    
    }
  }

  generateURL = (id, doc) => {
    // generate link for transcript based on the existence of transcriptURL file status
    this.props.firebase.storage.ref().child(doc.transcriptURL)
      .getDownloadURL()
      .then(url =>{
        this.setState({downloadURL : url}) 
      })
      .catch((error) => {
        console.error('could not assign a transcript URL');
        this.props.firebase.firestore
          .collection(process.env.REACT_APP_SESSION)
          .doc(id)
          .update({
            deleted : true
          })
      })
  }

  deleteFiles = event => {
    const documentID = event.target.id;
    console.log('deleting this document ID', documentID)
    this.props.firebase.firestore
      .collection(process.env.REACT_APP_SESSION)
      .doc(documentID)
      .update({
        retrieved : true
      }).catch(console.error);
    this.props.firebase.firestore
      .collection(process.env.REACT_APP_SESSION)
      .doc(documentID)
      .get()
      .then(document => {
        const folderRef = document.data().transcriptURL.split('/').slice(0,-1).join('/');
        console.log('this is the folder to delete: ', folderRef, document.id);
        var cleanupFolder = this.props.firebase.functions.httpsCallable('cleanupFolder');
        cleanupFolder({folder : folderRef, id:document.id}).then( result => {
            console.log('delete the current folder', JSON.stringify(result), JSON.stringify(folderRef));
            this.props.firebase.firestore
              .collection(process.env.REACT_APP_SESSION)
              .doc(result.data.id)
              .update({
                deleted : true
              }).catch(console.error);
          })
          .catch(console.error)
      })
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged((user) =>  {
      if (user) {
        var jobList = this.state.jobList;
        this.setState({currentUser : user.uid})
        this.listener = this.props.firebase.firestore
          .collection(process.env.REACT_APP_SESSION)
          .where('userID', '==', this.state.currentUser)
          .onSnapshot( snapshot => {
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                if (!change.doc.data().deleted) {
                  jobList = [...jobList, {id : change.doc.id, ...change.doc.data()}]
                  if (change.doc.data().transcriptURL && !change.doc.data().retrieved) {
                    this.generateURL(change.doc.id, change.doc.data())
                  }  
                }
              }
              if (change.type === 'modified') {
                this.setState({progress: 0, error : null});
                const index = jobList.findIndex(job => job.id === change.doc.id);
                jobList[index] = {id : change.doc.id, ...change.doc.data()}
                // if will processing finished - generate download URL
                if (change.doc.data().processed && !change.doc.data().deleted) {
                  this.generateURL(change.doc.id, change.doc.data())
                }
              }
              if (change.type === 'removed') {
                const index = jobList.findIndex(job => job.id === change.doc.id);
                jobList.splice(index,1)
              }
            })
            this.setState({jobList : jobList})
          }, error => {
            console.log('there was an issue with the firestore listener', error)
          } )
      } else {
        console.log('no user defined yet')
      }
    })
  }

  componentWillUnmount() {
    this.listener();
  }

  render () {
    return (
      <div className="container-sm-auto text-center overflow-auto">
        <table className="table table-condensed">
          <tbody>
              {this.state.jobList.map(job => (
                <tr key={job.id}>
                  <td>{job.originalfileName}</td>
                  <td className={job.uploaded? "table-success":"table-warning"}>{job.uploaded? "uploaded" : "uploading..."}</td>
                  <td className={job.processed? "table-success": job.uploaded? "table-warning" : ""}>{job.processed? "processed" : job.uploaded? "starting transcript..." : ""}</td>
                  <td className={job.retrieved? "table-success": job.processed? "table-info" : ""}>{job.retrieved? "retrieved" : job.processed? <a id={job.id} href={this.state.downloadURL} download="your transcript.txt" target="_blank" onClick={this.deleteFiles}>link to transcript</a>:""}</td>
                  <td className={job.deleted? "table-success":""}> {job.deleted? "deleted" : ""}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

class MainPage extends Component{
  constructor (props) {
    super(props);
    this.state = {
      imageCaptureState : 'pending',
      error : null,
      uploadProgress : 0,
      languageSelection : 'fr-FR'
    };
    this.fileUpload = React.createRef();
  }

  uploadManager = event => {
    event.preventDefault();
    this.fileUpload.current.click()
  }

  fileCapture = event => {
    this.props.firebase
      .doCallUser()
      .catch(error => {
        this.setState({error})
        console.log('error', error)
      });
    const fileContent = event.target.files[0];
    const isAudio = fileContent.type.split('/')[0]==='audio';
    const filesizeLimit = 500;
    const isLarge = fileContent.size > filesizeLimit *1024*1024;
    if (isAudio && !isLarge) {
      this.setState({
        uploadFile : event.target.files[0],
        imageCaptureState : 'capturing',
        filetypeError : null
      })
    } else {
      this.setState({
        filetypeError : `please select ${isAudio ? `a file`:`an audio file`}${isLarge ? ` smaller than ${filesizeLimit}Mo`:``}`,
        imageCaptureState : 'pending',
      })
    }

  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onSubmit = event => {
    event.preventDefault();
    this.setState({
      imageCaptureState : 'pending',
      languageSelection : event.target.languageSelection.value
    });
    // initialise variables
    const jobID = uuid();
    const fileName = jobID + this.state.uploadFile.name.substring(this.state.uploadFile.name.lastIndexOf('.')) 

    // create and record event in Firestore first and then after finished upload
    this.props.firebase.auth.onAuthStateChanged((user) => {
        this.props.firebase.firestore
        .collection(process.env.REACT_APP_SESSION)
        .add({
          jobID : jobID,
          userID : user.uid || 'no user defined',
          originalfileName : this.state.uploadFile.name,
          uploaded : false,
          processed : false,
          retrieved : false,
          deleted : false,
          languageSelection : this.state.languageSelection
        })
        .then(res => {
          var metadata = {
            cacheControl: 'public, max-age=31536000',
            customMetadata   : {
              sessionID : res.id,
              processVM : true
            }
          }

          // upload the file to storage
          // get a timer to track 60s for iOS timeout limit
          var startuploadTime = performance.now();
          var uploadTask = this.props.firebase.storage
            .ref(
              process.env.REACT_APP_UPLOAD_BUCKET+'/'+
              user.uid+'/'+
              jobID+'/'+
              fileName
            )
            .put(this.state.uploadFile, metadata)
          // Register three observers to be able to update upload progress:
          // 1. 'state_changed' observer, called any time the state changes
          // 2. Error observer, called on failure
          // 3. Completion observer, called on successful completion
          
          uploadTask.on('state_changed', snapshot => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            
            this.setState({uploadProgress: progress})
            // console.log('this is the upload state', JSON.stringify(this.state.error));
            switch (snapshot.state) {
              case 'canceled' :
                console.log('upload is canceled');
                this.setState({error : {message : 'upload cancelled ' + snapshot.state}});
                break;
              case 'paused' : // used during testing of issue on iOS. Pause functionality not implemented
                console.log('Upload is paused');
                this.setState({error : {message : 'upload ' + snapshot.state}});
                break;
              case 'running' :
                this.setState({error : {message : 'upload ' + snapshot.state + " at " + Math.round(progress) + "% started "+Math.round(performance.now()-startuploadTime)/1000+"s ago"}})
                break;
              default :
                console.log('triggered the default case of upload', snapshot.state)
                this.setState({error : {message : 'upload ' + snapshot.state + " at " + Math.round(progress) + "% ("+snapshot.bytesTransferred +")"}})
            }
          }, (error) => {
              console.error('There was an error uploading a file to Cloud Storage:', JSON.stringify(error));
              this.setState({uploadProgress: 0, error : {message : 'there is an issue with upload\n' + error.message}});
              this.props.firebase.firestore // set-up the file for delete so the record disappears from the table
                .collection(process.env.REACT_APP_SESSION)
                .doc(res.id)
                .update({
                  deleted : true,
                })
                .catch(error => {
                  console.error('There was an error updating the firestore record:', error);
                  });
          }, (result) => {
            // Handle successful uploads on complete
            this.setState({error : {message : 'upload completed, starting transcript...'}, uploadProgress : 0})
            console.log('upload finished')
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              //update the firestore document with upload status and file URL
              this.props.firebase.firestore
                .collection(process.env.REACT_APP_SESSION)
                .doc(res.id)
                .update({
                  uploaded : true,
                  originalfileURL : downloadURL
                })
                .catch(error => {
                  console.error('There was an error updating the firestore record:', error);
                  });
                this.setState({error : null})
            });
          });
        }) 
        .catch(error => {
          console.error('There was an error creating the firestore record:', error);
        });
    })
  }


  render () {
    const error=this.state.error;
    let icon;
    const filetypeError = this.state.filetypeError;

    if (this.state.imageCaptureState === 'pending') {
      icon = <MusicPlayer size="25" alt="" fill ="#a32222"/>
    } else {
      icon = <CheckCircle size="25" alt="" fill="green" />
    } 

    return (
      <div className="container">
        <div className="row">
          <div className="container-sm-auto text-center">
            <h1>Upload your audio to get your transcript</h1>
            <p>The file will be processed in about 5 to 15min on the cloud and <b> will be available for download once</b>. It will be immediately deleted after processing. </p>
            <p> There will be no copy made. If you loose your transcript, you will have to start the process from beginning.</p>
            {(filetypeError)? <div className="alert alert-danger" role="alert">{filetypeError}</div> : null}
            <form className="form" onSubmit={this.onSubmit}>
              <div className="row justify-content-center">
                <div className="custom-file">
                  <input 
                    style = {{display : 'none'}}
                    type="file" 
                    className="custom-file-input"
                    // accept="audio/*"
                    id="upload"
                    ref = {this.fileUpload}
                    onChange = {this.fileCapture}
                    />
                </div>
                <button className="btn btn-light" type="button" onClick={this.uploadManager}> 
                  {icon}
                </button>

                <button 
                    type="submit"
                    className = "btn btn-lng btn-primary"
                    disabled  = {(this.state.imageCaptureState ==='pending')? "disabled" : null}
                > Upload Transcript 
                </button>
              </div>
              <div className="row justify-content-center">
                  <div className="form-check form-check-inline">
                    <input 
                      className="form-check-input" 
                      type="radio" 
                      name="languageSelection" 
                      id="radio1" 
                      value="fr-FR"
                      checked = {this.state.languageSelection === "fr-FR"} 
                      onChange = {this.onChange}
                    />
                    <label className="form-check-label" htmlFor="radio1">
                      Audio in french
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input" 
                      type="radio" 
                      name="languageSelection" 
                      id="radio2" 
                      value="en-US"
                      checked = {this.state.languageSelection === "en-US"} 
                      onChange = {this.onChange}
                    />
                    <label className="form-check-label" htmlFor="radio2">
                      Audio in English
                    </label>
                  </div>
                  <div className="container .bg-info text-secondary">
                    {error && <p>{error.message}</p>}
                  </div>
              </div>  
            </form>
            <p><span role="img">⚠️</span> While the file is uploading,<strong> do not close the window</strong><span role="img">⚠️</span></p>
            <p> <span role="img">&#128034;</span> <strong>On iOS</strong> use 4G or Fiber to keep the upload within 1min. If not split the file in smaller bits. <span role="img">&#128034;</span> </p>
            <div className="progress" style={{height: "2px"}}>
              <div className="progress-bar" role="progressbar" style={{width: this.state.uploadProgress+"%"}}></div>
            </div>
          </div>
        </div>
        <div className="row align-items-start">
            <JobProcess firebase={this.props.firebase}/>
        </div>

      </div>
    )}
}

const App = () => (
  <FirebaseContext.Consumer>
    {firebase => {
      return <MainPage firebase={firebase} />;
    }}
  </FirebaseContext.Consumer>
)

export default App;
